<template src="./addpromotion.html">    
</template>
<style scoped src="./addpromotion.css">
</style>

<script>
import PromotionService from '@/services/PromotionService'
import Loading from 'vue-loading-overlay'
import promotionConstants from '@/constants/PromotionConstants.vue'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import modal from '@/components/modal/notification/modal'
export default {
    data() {
        return {
            promoCodeText : '',
            promo : {
                expirationDate : '',
                nomination : '',
                isFistTransation : false,
                isGreaterThenFee : false,
                issueId : '',
                name : '',
                promoType : '',
                currency : 'CAD',
                issuedToEmail : '',
                additionalInfo : '',
                reasponForIssue: '',
            },
            isView : false,          
            promoTypeList : promotionConstants.promoTypeList,
            reasonForIssueList : promotionConstants.reasonForIssueList,
            currencyList : promotionConstants.currencyList,
            hideAdditionalInfo: true,
            loaderVisible : false,
            errorMessages: applicationConstants.formsMessages,
            promoDetails: {},
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            }
        }
    },
    computed : {
        minDate () {
            return new Date()
        }
    },
    methods : {
        nominationRestriction ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.promo.nomination.indexOf('.') != -1)) {
                $event.preventDefault();
            }
            if (this.promo.nomination !== null && this.promo.nomination.indexOf('.') > -1  && (this.promo.nomination.split('.')[1].length > 1)) {
                $event.preventDefault();
            }
        },
        reasonForIssueListChangeEvent (event) {
            this.promo.reasponForIssue = event
            if (event === 'Other'){
                this.hideAdditionalInfo = false
            } else {
                this.hideAdditionalInfo = true
                this.promo.additionalInfo = ''
            }
        },
        async onSubmit () {            
            this.$v.promo.$touch()
            let reason = this.promo.reasponForIssue 
            if (this.promo.reasponForIssue === 'Other') {
                reason += ' - ' + this.promo.additionalInfo
            }          
            if (this.$v.promo.$invalid)  {
                return
            }
            this.loaderVisible = true
            await PromotionService.methods.UpdatePromotionDetails({
                'OperationType'   : 'A',
                'PromoCodeDetails':  {
                    'Currency'             : this.promo.currency,
                    'ExpirationDate'       : this.promo.expirationDate,
                    'FirstTransactionOnly' : this.promo.isFistTransation ? "True" : "False",
                    'IssuedTo'             : this.promo.issuedToEmail,
                    'Nomination'           : this.promo.nomination,
                    'IsIndividualCode'     : this.promo.promoType,
                    'ReasonIssued'         : reason,
                    'AllowGreaterThanFee'  : this.promo.isGreaterThenFee ? "True" : "False",
                    'Name'                 : this.promo.name,
                    'Code'                 : this.promo.promoCodeText,
                    'Issue'                : this.promo.issueId
                }
            })
            .then( res => {
                let promoDetails = res.csManagePromoCodeResponse.PromoCodeDetails
                promoDetails =  promoDetails ? Array.isArray(promoDetails) ? promoDetails : [promoDetails] : []
                const mapPromo = (promo) => {
                    Object.values(promo).map((el, key) => {
                        const objectkey = Object.keys(promo)[key]
                        promo[objectkey] = el._text
                    })
                }
                promoDetails.map(mapPromo)
                this.promoDetails = promoDetails[0]
                this.$refs['my-modal'].show()
                this.loaderVisible = false
            })
            .catch(error => {
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)
                this.loaderVisible = false
            })
            
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }, 
        onClear() {
            this.promo.expirationDate = '',
            this.promo.nomination = ''
            this.promo.isFistTransation = false
            this.promo.isGreaterThenFee = false,
            this.promo.promoCodeText = ''
            this.promo.issueId = ''
            this.promo.name = ''
            this.promo.promoType = ''
            this.promo.issuedToEmail = ''
            this.promo.additionalInfo = ''
            this.promo.reasponForIssue= ''
        },
        onAddNewPromo() {
            this.onClear()
            this.isView = false
        }
    },
    components : {
        Loading, modal
    },
    validations : {
         promo : {
            name : { required },
            promoType : { required },       
            nomination : { required },
            expirationDate : { required },
            issuedToEmail : {
                required : requiredIf(function() {
                    return this.promo.promoType == 'True' || !this.promo.promoType 
                }),
                email
            },   
            currency : { required },
            reasponForIssue :{
                required 
            },
            additionalInfo: {
                required : requiredIf(function () {
                    return !this.hideAdditionalInfo
                })
            }
        }     
    }
}
</script>